import { useEffect, useState } from "react";
import { getIntructionVideo } from "../../../lib/firebase/storage";
import PageTransition from "../../Common/PageTransition";
import Loading from "../../Common/Loading";

export default function InstructionVideo() {
    const [videoUrl, setVideoUrl] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchVideoUrl = async () => {
            try {
                const url = await getIntructionVideo();
                if (url) setVideoUrl(url);
            } catch (error) {
                console.error("Erro ao buscar o vídeo:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchVideoUrl();
    }, []);

    return (
        <>
            <PageTransition>
                <div style={{ height: '100%', width: '100%' }}>
                    {!loading && videoUrl && (
                        <video controls controlsList="nodownload" autoPlay loop style={{ width: '100%', height: '100%' }}>
                            <source src={videoUrl} type="video/mp4" />
                            Seu navegador não suporta a exibição de vídeos.
                        </video>
                    )}
                </div>
            </PageTransition>
            <Loading show={loading} />
        </>
    );
}
