import '../../../../UI/Pages/App/Room/Room.css'
import AppBackground from "../AppBackground";
import RoomHeader from "./RoomHeader";
import notification_icon from '../../../../Assets/Icons/icon-notification-vector.svg';
import menu_icon from '../../../../Assets/Icons/icon-menu.svg';
import PerfilCard from "./PerfilCard";
import { useEffect, useState } from 'react'
import { deleteUser, updateUser } from "../../../../lib/firebase/firestore";
import useCurrentUser from "../../../State/Hooks/useCurrentUser";
import { deleteImage } from "../../../../lib/firebase/storage";
import EmptyRoom from "./EmptyRoom";
import { useNavigate } from "react-router-dom";
import PageTransition from '../../../Common/PageTransition';
import { requestForToken } from '../../../../lib/firebase/notification';
import FilterRoom from './FilterRoom';
import TakeSelfie from './TakeSelfie';
import useGeolocation from '../../../State/Hooks/useGeolocation';

const genders = [
    { value: "Todos", label: "Todos" },
    { value: "Homem", label: "Homens" },
    { value: "Mulher", label: "Mulheres" },
    { value: "Outro", label: "Não especificado" },
]

const distanceFromLocation = process.env.DISTANCE_FROM_LOCATION || 100;

const Room = () => {
    const { currentUser, messages, usersList, currentRoom, setCurrentUser, removeCurrentUserRoom } = useCurrentUser();
    const [selectedFilter, setSelectedFilter] = useState('Todos');
    const navigate = useNavigate();
    const { currentLocation, haversineDistance } = useGeolocation();

    window.scrollTo(0, 0);

    useEffect(() => {
        const getNotificationPermission = async () => {
            const deviceId = await requestForToken();
            if (currentUser.id && deviceId) {
                await updateUser(currentUser.id, { deviceId: deviceId });
                setCurrentUser(prev => ({ ...prev, deviceId }));
            }
        }
        getNotificationPermission();
    }, []);

    useEffect(() => {
        if (currentLocation && currentRoom?.id) {
            const [roomLatitude, roomLongitude] = currentRoom.id.split(",").map(Number);
            const distance = haversineDistance(currentLocation, { latitude: roomLatitude, longitude: roomLongitude });
            if (distance > +distanceFromLocation && currentUser.id) {
                removeCurrentUserRoom(currentUser.id)
            };
        }
    }, [currentLocation])

    const filteredUsers = usersList
        // ?.filter(user => user.loggedIn === true)
        ?.filter(user => user?.strikes?.length > 2 ? false : true) // usuarios com mais de 2 strikes nao aparecem.
        ?.filter(user => {
            if (selectedFilter === 'Todos') return true;
            return user.gender === selectedFilter;
        });

    return (
        <>
            {
                currentUser.photo
                    ? (<AppBackground>
                        <PageTransition>
                            <div className="room-main-div">
                                <RoomHeader
                                    leftButton={
                                        <button className='room-header-icon-button' onClick={() => navigate('/notifications')}>
                                            {/* <img src={small_logout_icon} alt="Logout Icon" /> */}
                                            <img src={notification_icon} alt="Notifications" />
                                            {messages?.filter(m => m.closed === true).length > 0 && (
                                                <div className="notification-background-counter">
                                                    <p className="notification-counter">{messages?.filter(m => m.closed === true).length}</p>
                                                </div>
                                            )}
                                        </button>}
                                    rightButton={
                                        <button className='room-header-icon-button' onClick={() => navigate('/menu')}>
                                            <img src={menu_icon} alt="Menu" />
                                        </button>}
                                />
                                <FilterRoom filters={genders} selected={selectedFilter} setFilter={setSelectedFilter} />

                                {filteredUsers.length > 0
                                    ? (<div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <div className="grid-container">
                                            {filteredUsers.map(user => <PerfilCard key={user.id} user={user} onBlink={() => navigate(`/blink/${user.id}`)} />)}
                                        </div>
                                    </div>)
                                    : (<div style={{ display: 'flex', justifyContent: 'center', marginTop: '8rem' }}>
                                        <EmptyRoom />
                                    </div>
                                    )
                                }
                            </div>
                        </PageTransition>
                    </AppBackground >)
                    : (<TakeSelfie />)
            }
        </>

    )
}

export default Room;