import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";

import { storage } from "./client";

import { updateUser } from "./firestore";

export async function updateUserImage(userId: string, image: any) {
	try {
		if (!userId)
			throw new Error("No restaurant ID has been provided.");

		if (!image || !image.name)
			throw new Error("A valid image has not been provided.");

		const photo = await uploadImage(userId, image);
		await updateUser(userId, { photo });

		return photo;
	} catch (error) {
		console.error("Error processing request:", error);
	}
}

async function uploadImage(userId: string, image: any) {
	const extension = image.name.split('.').pop();
	const filePath = `photos/${userId}.${extension}`;
	const newImageRef = ref(storage, filePath);
	await uploadBytesResumable(newImageRef, image);

	return await getDownloadURL(newImageRef);
}

export async function deleteImage(userId: string, imageUrl: string) {
    try {
        if (!userId) throw new Error("No user ID has been provided.");
        if (!imageUrl) throw new Error("A valid image URL has not been provided.");

        const fileRef = ref(storage, imageUrl);
        await deleteObject(fileRef);

        console.log(`Image at ${imageUrl} deleted successfully.`);
    } catch (error) {
        console.error("Error deleting image:", error);
    }
}

export async function getIntructionVideo() {
	try {
		const videoRef = ref(storage, 'instruction/blinker.mp4');
		return await getDownloadURL(videoRef);
	} catch (error) {
		console.error("Error getting video:", error);
	}
}