
import { RecoilRoot } from 'recoil';
import ResolveAuth from './Components/Pages/ResolveAuth';
// import logo_extenso from './Assets/logos/logo_extenso.svg'
// import { Route, Routes } from "react-router-dom";
import './App.css';
import ReactPixel from 'react-facebook-pixel';
//import { useEffect } from 'react';


const App = () => {
  const pixelIds = process.env.REACT_APP_PIXEL_IDS?.split(',') || [];

  pixelIds.forEach((id) => {
    ReactPixel.init(id.trim());
  });

  ReactPixel.pageView();
  /*
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      const alreadyRefreshed = sessionStorage.getItem("pwa-refreshed");

      if (!alreadyRefreshed) {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
          registrations.forEach((registration) => registration.unregister());
          sessionStorage.setItem("pwa-refreshed", "true");
          window.location.reload();
        });
      }
    }
  }, []);
*/

  return (

    <RecoilRoot>
      {/* <div>V0.0001</div> */}

      <ResolveAuth />

    </RecoilRoot>
  )

  // const Pesquisa = () => {
  //   window.location.href = 'https://forms.gle/eWVSs4p3SSd2NoE66';
  //   return <></>;
  // }

  // return (

  //   <Routes>
  //     <Route path="/pesquisa" element={<Pesquisa />} />
  //     <Route path="*" element={<App />} />
  //   </Routes>

  // );



  //PARA FECHAR SALA E MANDAR PARA PESQUISA!!
  // window.location.href = 'https://forms.gle/eWVSs4p3SSd2NoE66';
  // return <></>;


}

export default App;
